import React from 'react';

function Navbar() {

    const [isActive, setisActive] = React.useState(false)
   
    return (
            <nav className="navbar is-light" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item is-active has-text-weight-semibold" href="#">Prabhash's Projects</a>

                    <a onClick={() => { setisActive(!isActive) }} role="button" className={`navbar-burger burger ${isActive ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target="projectSiteNavbar">
                        <span aria-hidden="true"/>
                        <span aria-hidden="true"/>
                        <span aria-hidden="true"/>
                    </a>   
                </div>
                <div id="projectSiteNavbar" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
                    <div className="navbar-start">
                        <a className="navbar-item is-active" href="#"><i className="fas fa-home"/>&nbsp;Home</a>
                        <a className="navbar-item" href="./#/projects"><i className="fas fa-code"/>&nbsp;Projects</a>
                        <a className="navbar-item" href="./#/reports"><i className="fas fa-file-alt"/>&nbsp;Reports</a>
                    </div>
                    <div className="navbar-end">
                        <div className="navbar-item">
                            <div className="buttons">
                                <a className="button is-primary" href="https://prabhashlk.com/" target="_blank" rel="noreferrer"><strong><i className="fas fa-globe-africa"/> Main Site </strong></a>
                                <a className="button is-info" href="https://blog.prabhashlk.com/" target="_blank" rel="noreferrer"><strong><i className="fas fa-blog"/> Blog </strong></a>
                                <a className="button is-info" href="https://github.com/optimuspnj/" target="_blank" rel="noreferrer"><strong><i className="fab fa-github"/> GitHub </strong></a>
                                <a className="button is-info" href="https://gitlab.com/optimuspnj/" target="_blank" rel="noreferrer"><strong><i className="fab fa-gitlab" /> GitLab </strong></a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav> 
    );
}

export default Navbar;