import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Reports from './pages/Reports';
import Error from './pages/Error';
import Navfooter from './components/Navfooter';

function App() {
  return (
    <>
    <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="projects" element={<Projects />} />
          <Route path="reports" element={<Reports />} />
          <Route path="*" element={<Error />} />
        </Routes>
      <Navfooter/>
    </>  
  );
}

export default App;