import React from 'react';

function Projects() {

    const [isActive, setisActive] = React.useState(false)

    return(
        <section className="section">
            <div className="container">
                <h1 className="title"> Prabhash's Projects </h1>
                <p className="subtitle">
                    <strong>Academic</strong> and <strong>Hobby</strong> projects I have initiated or participated. (Visit GitHub and GitLab profiles for more!) 
                </p>
                <article className="panel is-success">
                    <p className="panel-heading"> Projects </p>
                    <p className="panel-tabs">
                        <a id="1st" onClick={() => { setisActive(!isActive) }} className={`${isActive ? '' : 'is-active'}`}>Web Based</a>
                        <a id="2nd" onClick={() => { setisActive(!isActive) }} className={`${isActive ? 'is-active' : ''}`}>Other</a>
                    </p>
                    <div id="yr1" className={`${isActive ? 'is-hidden' : ''}`}>
                        <a  href="https://sinhalaunicode.tk/" target="_blank" rel="noreferrer" className="panel-block"> <span className="panel-icon"> <i className="fas fa-code"></i> </span> Sinhala Unicode Converters <button className="button is-primary is-rounded is-small mx-2" onclick="window.open('https://sinhalaunicode.tk/')"><i className="fas fa-angle-double-right mr-1"></i> Visit</button> <span className="panel-icon tooltip"> <span className="tooltiptext">Hobby Project</span> <i className="fas fa-gamepad"></i> </span> </a> 
                        <a  href="https://edit.prabhashlk.com" target="_blank" rel="noreferrer" className="panel-block"> <span className="panel-icon"> <i className="fas fa-code"></i> </span> EditPad <button className="button is-primary is-rounded is-small mx-2" onclick="window.open('https://edit.prabhashlk.com')"><i className="fas fa-angle-double-right mr-1"></i> Visit</button> <span className="panel-icon tooltip"> <span className="tooltiptext">Hobby Project</span> <i className="fas fa-gamepad"></i> </span> </a> 
                    </div>
                    <div id="yr2" className={`${isActive ? '' : 'is-hidden'}`}> 
                        <a  href="https://github.com/ipmanlk/exypnos-app" target="_blank" rel="noreferrer" className="panel-block"> <span className="panel-icon"> <i className="fas fa-code-branch"></i> </span> Exypnos - Android App <button className="button is-info is-rounded is-small mx-2" onclick="window.open('https://github.com/ipmanlk/exypnos-app')"> <i className="fab fa-gitlab mr-1"></i> GitLab</button> <span className="panel-icon tooltip"> <span className="tooltiptext">Hobby Project</span> <i className="fas fa-gamepad"></i> </span> </a> 
                    </div>
                </article>
            </div>
        </section>
    );
}

export default Projects;