function Error() {
    return(
        <section className="hero is-light has-text-centered">
            <div className="hero-body">
                <div className="container">
                    <h1 className="title"><i className="fas fa-exclamation-triangle" /> ERROR 404: PAGE NOT FOUND!</h1>
                    <h2 className="subtitle">Please check the URL again...</h2>
                </div>
            </div>
        </section>
    );
}

export default Error;