function Navfooter() {

    const year = new Date().getFullYear();

    return (
        <footer className="footer">
            <div className="content has-text-centered is-info">
                <p>
                    <a href="https://prabhashlk.com/" target="_blank" rel="noreferrer"> <strong>PrabhashLK.COM</strong> </a> website network by <strong>Prabhash Jayaweera</strong> (2018 - { year }) <br /> All Rights Reserved 
                </p>
            </div> 
        </footer>
    );
}

export default Navfooter;