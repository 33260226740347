import React from 'react';
import rep01 from "../reports/y1/cache_memory.pdf"
import rep02 from "../reports/y1/computer_networks.pdf"
import rep03 from "../reports/y1/erp_report.pdf"
import rep04 from "../reports/y1/external_memory.pdf"
import rep05 from "../reports/y1/ieee_for_srs.pdf"
import rep06 from "../reports/y1/interrupts_bus.pdf"
import rep07 from "../reports/y1/tcl.pdf"

import rep08 from "../reports/y2/business_economics.pdf"
import rep09 from "../reports/y2/computer_networks2.pdf"
import rep10 from "../reports/y2/computer_networks.pdf"
import rep11 from "../reports/y2/java.pdf"
import rep12 from "../reports/y2/ooad.pdf"
import rep13 from "../reports/y2/piit.pdf"

import rep14 from "../reports/y3/final_report.pdf"
import rep15 from "../reports/y3/group_report.pdf"
import rep16 from "../reports/y3/hci01.pdf"
import rep17 from "../reports/y3/hci02.pdf"
import rep18 from "../reports/y3/integration_mngmnt.pdf"
import rep19 from "../reports/y3/rapid_agile.pdf"
import rep20 from "../reports/y3/research_review.pdf"

function Reports() {
    const [active, setActive] = React.useState(1);
   
    return(
        <section className="section"> 
            <div className="container">
                <h1 className="title"> Prabhash's Academic Reports </h1>
                <p className="subtitle"> My academic reports of <strong>Bachelor of Information and Communication Technology Honours - BICT(Hons)</strong> Degree </p> 
                <article className="panel is-success">
                    <p className="panel-heading"> Reports </p>
                    <p className="panel-tabs"> 
                        <a id="1st" onClick={() => setActive(1)} className={`${active === 1 ? 'is-active' : ''}`}>1st Year</a>
                        <a id="2nd" onClick={() => setActive(2)} className={`${active === 2 ? 'is-active' : ''}`}>2nd Year</a>
                        <a id="3rd" onClick={() => setActive(3)} className={`${active === 3 ? 'is-active' : ''}`}>3rd Year</a>
                        <a id="4th" onClick={() => setActive(4)} className={`${active === 4 ? 'is-active' : ''}`}>4th Year</a>
                    </p> 
                    <div id="yr1" className={`${active === 1 ? '' : 'is-hidden'}`}>
                        <a className="panel-block" href={rep01} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> Cache Memory </a>
                        <a className="panel-block" href={rep02} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> Computer Networks </a> 
                        <a className="panel-block" href={rep03} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> Enterprise Resource Planning (ERP) </a>
                        <a className="panel-block" href={rep04} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> External Memory Technologies </a> 
                        <a className="panel-block" href={rep05} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> IEEE Standard for Software Requirements Specifications </a>
                        <a className="panel-block" href={rep06} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> Interrupts and Bus Architecture </a>
                        <a className="panel-block" href={rep07} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> TCL/TK Scripting Language </a> 
                    </div>
                    <div id="yr2" className={`${active === 2 ? '' : 'is-hidden'}`}>
                        <a className="panel-block" href={rep08} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> Should the government intervene in the market? </a>
                        <a className="panel-block" href={rep09} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> Network Simulators and Packet Analyzers </a> 
                        <a className="panel-block" href={rep10} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> Computer Networks </a>
                        <a className="panel-block" href={rep11} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> Java Project </a> 
                        <a className="panel-block" href={rep12} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> The fully dressed format use case </a>
                        <a className="panel-block" href={rep13} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> Human Resource Issues Arise in IT industry Due to COVID-19 </a>
                    </div>
                    <div id="yr3" className={`${active === 3 ? '' : 'is-hidden'}`}>
                        <a className="panel-block" href={rep14} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> Advanced programming in Java </a>
                        <a className="panel-block" href={rep15} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> TecMIS Interface Redesign </a> 
                        <a className="panel-block" href={rep16} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> Human Computer Interaction </a>
                        <a className="panel-block" href={rep17} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> PACT Analysis </a> 
                        <a className="panel-block" href={rep18} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> Project Integration Management </a>
                        <a className="panel-block" href={rep19} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> Rapid and Agile Software Development </a>
                        <a className="panel-block" href={rep20} target="_blank" rel="noreferrer"> <span className="panel-icon"> <i className="fas fa-book" aria-hidden="true"></i> </span> Research Review </a>
                    </div> 
                    <div id="yr4" className={`${active === 4 ? '' : 'is-hidden'}`}> 
                        <a className="panel-block"> <span className="panel-icon"> <i className="fas fa-exclamation-triangle" aria-hidden="true"></i> </span> (NO DATA) </a>
                    </div>
                </article>
            </div>
        </section>
    );
}

export default Reports;