function Home() {
    return(
        <div>
            <section className="hero is-light has-text-centered">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="title"> <i className="fas fa-code-branch"></i>My Projects and Reports</h1>
                        <h2 className="subtitle"> ACTIVE projects and Academic reports. (Visit my GitLab and GitHub profiles to see more...)</h2>
                    </div>
                </div>
            </section>
            <div className="container" style={{marginTop: '1rem', marginBottom: '1rem'}}>
                <div className="columns">
                    <div className="column">
                        <div className="card custom-zoom" id="webaccard" style={{borderRadius: '1rem', marginLeft: '1rem', marginRight: '1rem'}}>
                            <header className="card-header">
                                <p className="card-header-title custom-card-header3"> <i className="fas fa-code-branch"></i>&nbsp;My Projects </p>
                            </header>
                            <div className="card-content">
                                <p className="is-size-5-desktop"> Checkout my Academic and Hobby projects </p>
                            </div>
                            <footer className="card-footer custom-card-footer3">
                                <p className="card-footer-item"> 
                                    <span> <i className="fas fa-angle-double-right mr-1" aria-hidden="true"></i>Projects
                                        <a className="divLink" href="./#/projects">Link</a>
                                    </span>
                                </p>
                            </footer>
                        </div>
                    </div>
                    <div className="column">
                        <div className="card custom-zoom" id="webnonaccard" style={{borderRadius: '1rem', marginLeft: '1rem', marginRight: '1rem'}}>
                            <header className="card-header">
                                <p className="card-header-title custom-card-header4"> <i className="fas fa-file-alt"></i>&nbsp;My Reports </p>
                            </header>
                            <div className="card-content">
                                <p className="is-size-5-desktop"> See my Academic reports. </p>
                            </div>
                                <footer className="card-footer custom-card-footer4">
                                <p className="card-footer-item">
                                    <span> <i className="fas fa-angle-double-right mr-1" aria-hidden="true"></i>Reports<a className="divLink" href="./#/reports">Link</a> </span> 
                                </p> 
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;